import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import "./Success.css";

const Success = () => {
  const navigate = useNavigate();
  return (
    <div className="success-container">
      <CheckCircleIcon className="icon" />
      <h2>Payment Successful!</h2>
      <span>
        Thank you for your payment. Your transaction has been completed
        successfully.
      </span>
      <button onClick={() => navigate("/")}>Continue Ordering</button>
    </div>
  );
};

export default Success;
