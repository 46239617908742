import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import "./Success.css";

const Failure = () => {
  const navigate = useNavigate();

  return (
    <div className="failure-container">
      <CancelIcon className="icon" />
      <h2>Payment Failed!</h2>
      <span>
        We encountered an issue while processing your payment. Please try again.
      </span>
      <div className="btns">
        <button onClick={() => navigate("/")}>Back to Order</button>
        <button onClick={() => navigate("/contact")}>Contact Support</button>
      </div>
    </div>
  );
};

export default Failure;
