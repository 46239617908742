import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "./Checkout.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import CheckIcon from "@mui/icons-material/Check";
import { setUser } from "../redux/features/userSlice";
import CryptoJS from "crypto-js";
import getUserData from "../utils/userDataService.js";

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );
  const [upi, setUpi] = useState(0); // 0 = pgateway.in || 1 = onegateway
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [mode, setMode] = useState("upi");
  const [processingOrder, setProcessingOrder] = useState(false); // Flag for processing order

  // DISCOUNT
  const [data, setData] = useState(null);
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [enteredCoupon, setEnteredCoupon] = useState("");
  const [finalAmount, setFinalAmount] = useState();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [balance, setBalance] = useState("");

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  //? Getting IP ADDRESS
  const [location, setLocation] = useState({
    ipAddress: null,
    latitude: null,
    longitude: null,
    address: {},
  });
  const [allowedLocation, setAllowedLocation] = useState(false);

  async function fetchIP() {
    try {
      const response = await fetch("https://api.ipify.org");
      const data = await response.text();
      setLocation((prevLocation) => ({ ...prevLocation, ipAddress: data }));
    } catch (error) {
      console.error("Failed to fetch IP:", error);
    }
  }

  useEffect(() => {
    fetchIP();
  }, []);

  //? getting location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLocation((prevLocation) => ({
            ...prevLocation,
            latitude,
            longitude,
          }));
          setAllowedLocation(true);
          const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
          try {
            const response = await fetch(url);
            const data = await response.json();
            setLocation((prevLocation) => ({
              ...prevLocation,
              address: data.address,
            }));
          } catch (error) {
            console.error("Error fetching data:", error);
            setError("Error fetching data");
          }
        },
        (error) => {
          console.error("Error getting location:", error);
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

  const secretKey = "DEERA@#$123games";
  const decryptPrice = (encryptedPrice) => {
    if (!secretKey) {
      console.error("Secret key is not defined");
      return encryptedPrice;
    }
    const bytes = CryptoJS.AES.decrypt(encryptedPrice, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const getAllCoupons = async () => {
    try {
      const res = await axios.get("/api/admin/get-coupons");
      if (res.data.success) {
        setData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleApplyDiscount = (e) => {
    e.preventDefault();

    if (data?.length === 0 || data === null) {
      return message.error("No Coupon Found");
    }

    if (enteredCoupon === "") {
      setError("Enter coupon");
      setTimeout(() => {
        setError("");
      }, 1500);
      return;
    }

    const coupon =
      data && data?.find((coupon) => coupon.name === enteredCoupon);
    if (coupon?.minValue >= total) {
      setError(`Min Order Value Is: ${coupon?.minValue}`);
      setTimeout(() => {
        setError("");
      }, 1500);
      return;
    }
    if (coupon) {
      setDiscountApplied(true);
      setDiscount(coupon?.discount);
      setFinalAmount(total - coupon?.discount);
      setSuccess("Coupon applied success");
      setTimeout(() => {
        setSuccess("");
      }, 1500);
    } else {
      setError("No coupon found");
      setTimeout(() => {
        setError("");
      }, 1500);
    }
  };

  const handleRemoveDiscount = () => {
    setDiscountApplied(false);
    setFinalAmount((prev) => prev + discount);
  };

  const calculateTotal = () => {
    const newTotal = cart.reduce(
      (acc, item) => acc + parseFloat(decryptPrice(item?.price)),
      0
    );
    setTotal(newTotal);
    setFinalAmount(newTotal);
  };

  const generateOrderId = () => {
    const numbers = "01234567"; // 10 numbers
    const randomNumbers = Array.from({ length: 7 }, () =>
      numbers.charAt(Math.floor(Math.random() * numbers.length))
    );
    const orderId = randomNumbers.join("");
    setOrderId(orderId);
    setLocation((prevLocation) => ({
      ...prevLocation,
      client_txn_id: orderId,
    }));
  };

  useEffect(() => {
    calculateTotal();
  }, [cart]);

  useEffect(() => {
    generateOrderId();
    getAllCoupons();
  }, []);

  function checkPlaceOrder(e) {
    if (cart[0]?.api === "yes") {
      if (cart[0]?.apiName === "yokcash") {
        if (mode === "upi") {
          handleYokcashUpiOrder(e);
        } else {
          handleYokcashWalletOrder(e);
        }
      } else {
        if (mode === "upi") {
          handleSmileOneUpiOrder(e);
        } else {
          handleSmileOneWalletOrder(e);
        }
      }
    } else {
      if (mode === "upi") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }

  async function handleYokcashUpiOrder(e) {
    e.preventDefault();
    try {
      const playerId =
        cart[0]?.playerId !== "" ? cart[0]?.playerId : cart[0]?.userId;

      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: finalAmount,
        udf1: cart[0]?.amount,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        redirectUrl:
          "https://deeragames.in/api/yokcash/check-yokcash-upi-order",
        paymentNote:
          playerId +
          "%" +
          cart[0]?.zoneId +
          "%" +
          decryptPrice(cart[0].productId) +
          "%" +
          cart[0].name +
          "%" +
          decryptPrice(cart[0].profit) +
          "%" +
          cart[0].amount,
      };

      const response = await axios.post("/api/upi/initiate", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleYokcashWalletOrder(e) {
    try {
      const playerId =
        cart[0]?.playerId !== "" ? cart[0]?.playerId : cart[0]?.userId;
      const paymentObject = {
        order_id: orderId,
        txn_amount: finalAmount,
        product_name: cart[0]?.amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          playerId +
          "%" +
          cart[0]?.zoneId +
          "%" +
          decryptPrice(cart[0].productId) +
          "%" +
          cart[0].name +
          "%" +
          decryptPrice(cart[0].profit),
      };
      setLoading(true);
      const res = await axios.post(
        "/api/yokcash/place-yokcash-from-wallet",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        const response = await axios.post(
          "/api/admin/save-location",
          location,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (response.data.success) {
          message.success(res.data.message);
        }
        localStorage.removeItem("cart");
        localStorage.setItem("orderProcess", "no");
        setLoading(false);
        setOrderSuccess(true);
      } else {
        setLoading(false);
        message.error(res.data.message);
        localStorage.setItem("orderProcess", "no");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    if (processingOrder) return;
    try {
      const playerId =
        cart[0]?.playerId !== "" ? cart[0]?.playerId : cart[0]?.userId;

      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: finalAmount, // 100rs
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        redirectUrl: "https://deeragames.in/api/payment/check-api-upi-order",
        paymentNote:
          playerId +
          "%" +
          cart[0]?.zoneId +
          "%" +
          decryptPrice(cart[0].productId) +
          "%" +
          cart[0].amount +
          "%" +
          cart[0].region +
          "%" +
          cart[0].name +
          "%" +
          decryptPrice(cart[0].profit),
      };

      const response = await axios.post("/api/upi/initiate", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      // redirecting user
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
        setProcessingOrder(true);
        setLoading(false);
      } else {
        message.error(response.data.message);
        setProcessingOrder(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setProcessingOrder(false);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    e.preventDefault();
    if (processingOrder) return; // If order is already being processed, return
    setProcessingOrder(true); // Set flag to indicate order processing
    if (localStorage.getItem("orderProcess") === "yes") {
      return message("Previous order is in process");
    }
    localStorage.setItem("orderProcess", "yes");
    const playerId =
      cart[0]?.playerId !== "" ? cart[0]?.playerId : cart[0]?.userId;
    const orderObject = {
      client_txn_id: orderId,
      userid: playerId,
      zoneid: cart[0]?.zoneId,
      productid: decryptPrice(cart[0]?.productId),
      region: cart[0]?.region,
      customer_email: user && user?.email,
      customer_mobile: user && user?.mobile,
      pname: cart[0]?.name,
      amount: cart[0]?.amount,
      price: finalAmount,
      profit: decryptPrice(cart[0].profit),
    };
    setLoading(true);
    const res = await axios.post(
      "/api/payment/place-order-from-wallet",
      orderObject,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (res.data.success) {
      const response = await axios.post("/api/admin/save-location", location, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      message.success(res.data.message);
      dispatch(setUser(res.data.data));
      localStorage.removeItem("cart");
      setLoading(false);
      setOrderSuccess(true);
      setProcessingOrder(false);
      localStorage.setItem("orderProcess", "no");
    } else {
      setLoading(false);
      message.error(res.data.message);
      setProcessingOrder(false);
      localStorage.setItem("orderProcess", "no");
    }
  };

  const handleUpiOrder = async (e) => {
    e.preventDefault();
    if (processingOrder) return;
    setProcessingOrder(true);
    try {
      const playerId =
        cart[0]?.playerId !== "" ? cart[0]?.playerId : cart[0]?.userId;

      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: finalAmount, // 100rs
        paymentNote:
          playerId +
          "@" +
          cart[0]?.amount +
          "@" +
          cart[0]?.name +
          "@" +
          decryptPrice(cart[0].profit), // userid + 10 dias + game name + profit
        customerName: user?.fname,
        customerNumber: user?.mobile,
        customerEmail: user?.email,
        redirectUrl: "https://deeragames.in/api/payment/check-manual-upi-order",
      };
      const response = await axios.post("/api/upi/initiate", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleWalletOrder = async (e) => {
    e.preventDefault();
    if (processingOrder) return;
    setProcessingOrder(true);

    if (localStorage.getItem("orderProcess") === "yes") {
      return message("Previous order is in process");
    }
    localStorage.setItem("orderProcess", "yes");

    try {
      setLoading(true);
      const playerId =
        cart[0]?.playerId !== "" ? cart[0]?.playerId : cart[0]?.userId;

      const orderObject = {
        api: "no",
        client_txn_id: orderId,
        userid: playerId,
        zoneid: cart[0]?.zoneId,
        productid: decryptPrice(cart[0]?.productId),
        region: cart[0]?.region,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: cart[0]?.name,
        amount: finalAmount,
        price: finalAmount,
        profit: decryptPrice(cart[0].profit),
      };
      const res = await axios.post(
        "/api/payment/place-order-non-api",
        orderObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        const response = await axios.post(
          "/api/admin/save-location",
          location,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        setLoading(false);
        dispatch(setUser(res.data.data));
        message.success(res.data.message);
        navigate("/orders");
        localStorage.removeItem("cart");
        setProcessingOrder(false);
        localStorage.setItem("orderProcess", "no");
      } else {
        message.error(res.data.message);
        setLoading(false);
        setProcessingOrder(false);
        localStorage.setItem("orderProcess", "no");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setProcessingOrder(false);
      localStorage.setItem("orderProcess", "no");
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (processingOrder) {
        const message =
          "Don't refresh the page, Otherwise you will lost the funds";
        event.preventDefault();
        event.returnValue = message;
        return message; // For older versions of Firefox
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [processingOrder]);

  useEffect(() => {
    if (!localStorage.getItem("orderProcess")) {
      localStorage.setItem("orderProcess", "no");
    }
  }, []);

  return (
    <Layout>
      {orderSuccess ? (
        <div className="order-success-container">
          <CheckIcon className="icon" />
          <span className="text-muted">Hey! {user?.fname}</span>
          <h4 className="my-1">Thank you for ordering!</h4>
          <span className="text-muted text-center">
            We have received your order and it will completed instantly!
          </span>
          <button
            onClick={() => {
              navigate("/orders");
              setOrderSuccess(false);
            }}
            className="add-to-cart-btn"
            style={{ borderRadius: "100px" }}
          >
            Check Order Status
          </button>
          <button
            onClick={() => {
              navigate("/");
              setOrderSuccess(false);
            }}
            className="add-to-cart-btn"
            style={{ borderRadius: "100px" }}
          >
            Go To Home
          </button>
        </div>
      ) : loading ? (
        <div className="order-placing-loader">
          <div class="me-2 spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <br />
          Do not refresh or take back Otherwise the funds will be lost.
          <br />
          Order Processing!
        </div>
      ) : (
        <div className="checkout-container">
          <div className="customer-form">
            {!user && (
              <h5 className="text-white">
                Already a customer?{" "}
                <Link className="sc" to="/login">
                  Login
                </Link>
              </h5>
            )}
            {user && (
              <div className="text-white">
                <h5>Account Details</h5>
                <div className="row">
                  <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                    <label className="form-label" htmlFor="">
                      Name :
                    </label>
                    <h5>{user && user?.fname}</h5>
                  </div>
                  <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                    <label className="form-label" htmlFor="">
                      Email :
                    </label>
                    <h5>{user && user?.email}</h5>
                  </div>
                  <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                    <label className="form-label" htmlFor="">
                      Mobile :
                    </label>
                    <h5>{user && user?.mobile}</h5>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* CART  */}
          <div className="checkout-product-details">
            <div className="checkout-item-container">
              <div className="d-flex justify-content-between">
                <span>Product</span>
                <span>Subtotal</span>
              </div>
              <hr />
              {cart &&
                cart.map((item, index) => {
                  return (
                    <div key={index} className="checkout-item">
                      <span>{item.name}</span>
                      <span>{decryptPrice(item.price)}</span>
                    </div>
                  );
                })}
              <hr />
              {/* <div className="checkout-item">
                <span>
                  <b>Wallet Balanceeee</b>
                </span>
                <span>
                  <b>
                    {user && user ? balance : <Link to="/login">Login</Link>}
                  </b>
                </span>
              </div> */}
              {discountApplied && (
                <div className="checkout-item">
                  <span>
                    <b>Coupon</b>
                    <span
                      className="ms-2 text-danger remove-c"
                      onClick={handleRemoveDiscount}
                    >
                      <small>(remove)</small>
                    </span>
                  </span>
                  <span>
                    <b> - Rs. {discount}</b>
                  </span>
                </div>
              )}
              {/* COUPON INPUT */}
              {!discountApplied && (
                <div className="apply-code-container">
                  <input
                    onChange={(e) => setEnteredCoupon(e.target.value)}
                    type="text"
                    name="coupon"
                    placeholder="Enter Coupon Code"
                  />
                  <button onClick={handleApplyDiscount}>Apply</button>
                </div>
              )}
              <div className="checkout-item">
                <span>
                  <b>Subtotal</b>
                </span>
                <span>
                  <b>Rs. {total}</b>
                </span>
              </div>
              {/* TOTAL  */}
              <div className="checkout-item">
                <h6>Total </h6>
                <h4 className="m-0">
                  <b>Rs. {finalAmount}</b>
                </h4>
              </div>
              {error !== "" && (
                <span className="text-danger m-0">
                  <small>{error}</small>
                </span>
              )}
              {success !== "" && (
                <span className="text-success m-0">
                  <small>{success}</small>
                </span>
              )}
              {user && <hr />}
              {user && (
                <div className="">
                  <span>
                    <b>Payment Mode</b>
                  </span>
                  <div className="">
                    <div
                      className={`form-check pmode ${
                        mode === "upi" && "active"
                      } d-flex justify-content-between`}
                      onClick={() => setMode("upi")}
                    >
                      <div>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="paymentMode"
                          id="upiRadio"
                          value="upi"
                          checked={mode === "upi"}
                          // onChange={() => setMode("upi")}
                          disabled={balance <= 0}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="walletRadio"
                        >
                          UPI
                        </label>
                      </div>
                      <span>GPAY/PHONEPE/PAYTM</span>
                    </div>
                    <div
                      className={`form-check pmode ${
                        mode === "wallet" && "active"
                      } d-flex justify-content-between`}
                      onClick={() => setMode("wallet")}
                    >
                      <div>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="paymentMode"
                          id="walletRadio"
                          value="wallet"
                          checked={mode === "wallet"}
                          disabled={balance <= 0}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="walletRadio"
                        >
                          Wallet
                        </label>
                      </div>
                      <span>DC {balance}</span>
                    </div>
                  </div>
                </div>
              )}
              {balance >= total && mode === "wallet" && (
                <div className="checkout-item">
                  <span>Balance after order</span>
                  <span>
                    <b>DC {balance - finalAmount}</b>
                  </span>
                </div>
              )}
            </div>
            {!user || user === null ? (
              <button
                onClick={() => navigate("/login")}
                className="w-100 add-to-cart-btn"
              >
                Login to Continue
              </button>
            ) : !allowedLocation ? (
              <button
                onClick={() => {
                  navigate("/contact");
                }}
                className="w-100 add-to-cart-btn"
              >
                Contact to Admin
              </button>
            ) : !user?.mobileVerified ? (
              <button
                onClick={() => {
                  message.error(`Verify Your "Mobile Number"}`);
                  navigate("/my-account");
                }}
                className="w-100 add-to-cart-btn"
              >
                Verify Email And Mobile
              </button>
            ) : user?.block === "yes" ? (
              <button
                onClick={() => navigate("/contact")}
                className="w-100 add-to-cart-btn"
              >
                Contact to Admin
              </button>
            ) : user && mode === "upi" ? (
              <button
                onClick={checkPlaceOrder}
                className="w-100 add-to-cart-btn"
              >
                Place Order
              </button>
            ) : processingOrder ? (
              <button disabled={true} className="w-100 add-to-cart-btn">
                Previous Order is in process
              </button>
            ) : localStorage.getItem("orderProcess") === "no" &&
              balance >= finalAmount ? (
              <button
                onClick={checkPlaceOrder}
                className="w-100 add-to-cart-btn"
              >
                Place Order
              </button>
            ) : localStorage.getItem("orderProcess") === "yes" ? (
              <button
                disabled={true}
                style={{ opacity: "0.7" }}
                className="w-100 add-to-cart-btn"
              >
                Prev Order Inprocess
              </button>
            ) : (
              balance < total && (
                <button
                  onClick={() => navigate("/wallet")}
                  className="w-100 add-to-cart-btn"
                >
                  Topup your wallet
                </button>
              )
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Checkout;
