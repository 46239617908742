import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import { setUser } from "../redux/features/userSlice";
import "./ReferAndEarn.css";

const ReferAndEarn = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [inviteCode, setInviteCode] = useState("");
  const [amount, setAmount] = useState("");

  async function getAmount() {
    try {
      const res = await axios.get("/api/refer/get-amount");
      if (res.data.success) {
        setAmount(res.data.data.amount);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAmount();
  }, []);

  function generateReferCode(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setInviteCode(result);
  }

  useEffect(() => {
    if (!user?.inviteCode) {
      generateReferCode(6);
    }
  }, []);

  function handleCopy() {
    try {
      const copyText = document.querySelector(".invite-code input");
      copyText.select();
      document.execCommand("copy");
      alert("Copied to clipboard: " + copyText.value);
    } catch (error) {
      console.log(error);
    }
  }

  async function saveInviteCode() {
    try {
      const res = await axios.post("/api/user/save-invite-code", {
        email: user?.email,
        inviteCode: inviteCode,
      });
      if (res.data.success) {
        dispatch(setUser(res.data.user));
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Layout>
      <DashboardLayout>
        <div className="refer-container">
          <h4 className="">Your Invite Code</h4>
          <div className="invite-code">
            <input
              className="form-control"
              type="text"
              value={
                user?.inviteCode ? user?.inviteCode : "Click on Generate Code"
              }
            />
            {user?.inviteCode ? (
              <button onClick={handleCopy}>Copy</button>
            ) : (
              <button onClick={saveInviteCode}>Generate</button>
            )}
          </div>
          <div className="notice">
            <ul>
              <h4>Important:</h4>
              <li>1) Create your unique referral code</li>
              <li>2) Copy referral code</li>
              <li>
                3) Refer your friend(user) and let them sign up using your
                referral code
              </li>
              <li>
                4) When the user adds 150₹ or more balance for the first time
                then you will get {amount}rs as reward
              </li>
              <li>
                Note - Only 1st time additions in wallet will be counted. For
                the second time it will not work.
                <br />
                I.e 1st payment to add money on wallet should be equal or
                greater than 150₹
              </li>
            </ul>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default ReferAndEarn;
