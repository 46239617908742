import React from "react";
import Layout from "../components/Layout/Layout";
import "./Search.css";

const Search = () => {
  return (
    <Layout>
      <div className="search-page-container">Search</div>
    </Layout>
  );
};

export default Search;
