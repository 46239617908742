import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message, Pagination, Select } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./AdminUsers.css";

const { Option } = Select;

const AdminUsers = () => {
  const navigate = useNavigate();
  const [allUser, setAllUser] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchMobile, setSearchMobile] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(50); // Default number of items per page
  const [balanceFilter, setBalanceFilter] = useState(""); // State for balance filter

  // Pagination logic
  const totalUsers = filteredUsers.length || allUser.length;

  // Pagination change handler
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  // Filter and Search
  const handleFilter = () => {
    let users = [...allUser];

    if (searchQuery.trim() !== "") {
      users = users.filter((user) =>
        user?.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (searchMobile.trim() !== "") {
      users = users.filter((user) => user?.mobile?.includes(searchMobile));
    }
    if (balanceFilter === "high-to-low") {
      users = users.sort((a, b) => b.balance - a.balance);
    } else if (balanceFilter === "low-to-high") {
      users = users.sort((a, b) => a.balance - b.balance);
    }

    setFilteredUsers(users);
    setCurrentPage(1);
  };

  const getAllUser = async () => {
    try {
      const res = await axios.get("/api/admin/get-all-users", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllUser(res.data.data);
        setFilteredUsers(res.data.data); // Set initially filtered users to all users
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleDelete(id) {
    const confirm = window.confirm("Are you sure to delete?");
    if (confirm) {
      try {
        const res = await axios.post(
          "/api/admin/delete-user",
          { id: id },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.success) {
          message.success(res.data.message);
          getAllUser();
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    getAllUser();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [searchQuery, balanceFilter, searchMobile]);

  const currentUsers =
    filteredUsers.length > 0
      ? filteredUsers.slice(indexOfFirstUser, indexOfLastUser)
      : allUser.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Customers</h3>
          <h6>Total Users - {allUser?.length}</h6>
        </div>
        <hr />
        <div className="table-container">
          <div className="tools">
            <div className="form-fields">
              <SearchIcon className="text-dark me-2" />
              <input
                className="mb-4"
                type="search"
                name="search"
                placeholder="Search by email"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="form-fields">
              <input
                className="mb-4"
                type="search"
                name="mobile"
                placeholder="Search by mobile"
                value={searchMobile}
                onChange={(e) => setSearchMobile(e.target.value)}
              />
            </div>
            <div className="form-fields">
              <select
                name="sortBalance"
                className="text-dark"
                onChange={(e) => setBalanceFilter(e.target.value)}
              >
                <option value="">Filter Using Balance</option>
                <option value="high-to-low">High to Low</option>
                <option value="low-to-high">Low to High</option>
              </select>
            </div>
            <Select
              defaultValue="50"
              style={{ width: 120 }}
              onChange={(value) => setUsersPerPage(value)}
            >
              <Option value="10">10</Option>
              <Option value="20">20</Option>
              <Option value="50">50</Option>
              <Option value="100">100</Option>
            </Select>
          </div>
          <table className="table user-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Is Blocked</th>
                <th>Reseller</th>
                <th>Balance</th>
                <th>Email Verified</th>
                <th>Mobile Verified</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers?.map((user, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <small>{user?.fname}</small>
                    </td>
                    <td>
                      <small>{user?.email}</small>
                    </td>
                    <td>
                      <small>{user?.mobile}</small>
                    </td>
                    <td>
                      <small>{user?.block}</small>
                    </td>
                    <td>
                      <small>{user?.reseller}</small>
                    </td>
                    <td>
                      <small>{user?.balance}</small>
                    </td>
                    <td>
                      <small>{user?.emailVerified ? "Yes" : "No"}</small>
                    </td>
                    <td>
                      <small>{user?.mobileVerified ? "Yes" : "No"}</small>
                    </td>
                    <td>
                      <small>
                        {user?.created
                          ? new Date(user?.created).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )
                          : ""}
                      </small>
                    </td>
                    <td>
                      <div className="d-flex gap-2">
                        <EditIcon
                          onClick={() =>
                            navigate(`/admin-edit-user/${user?._id}`)
                          }
                          className="me-2 text-muted"
                        />
                        <DeleteIcon
                          className="text-danger"
                          onClick={() => handleDelete(user?._id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="pagination-container">
            <Pagination
              current={currentPage}
              total={totalUsers}
              pageSize={usersPerPage}
              onChange={onPageChange}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminUsers;
