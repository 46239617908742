import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import Multiselect from "multiselect-react-dropdown";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import "./AdminAddProduct.css";
import "./AdminUsers.css";

const AdminAddProduct = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [images, setImages] = useState(null);
  const [moogold, setMoogold] = useState(null);
  const [servers, setServers] = useState(null);
  const [yokcash, setYokcash] = useState(null);
  const [value, setValue] = React.useState(dayjs(new Date()));
  const [form, setForm] = useState({
    name: "",
    desc: "",
    api: "",
    apiName: "",
    gameName: "",
    region: "",
    tag: "",
    stock: "Yes",
    category: "",
    use: "",
    youtubeLink: "",
    descTwo: "",
    image: "",
  });
  const [cost, setCost] = useState([
    { id: "", amount: "", price: "", pimg: "", resPrice: "", profit: "" },
  ]);

  const handleCategoryChange = (selectedList) => {
    setForm((prevForm) => ({
      ...prevForm,
      category: selectedList,
    }));
  };

  const handleAddCostField = (index) => {
    const updatedCost = [...cost];
    updatedCost.splice(index + 1, 0, {
      id: "",
      amount: "",
      price: "",
      pimg: "",
      resPrice: "",
      profit: "",
    });
    setCost(updatedCost);
  };

  const handleRemoveCostField = (index) => {
    const updatedCost = [...cost];
    updatedCost.splice(index, 1);
    setCost(updatedCost);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setForm({ ...form, [name]: e.target.files });
    } else if (
      name.startsWith("id") ||
      name.startsWith("amount") ||
      name.startsWith("price") ||
      name.startsWith("pimg") ||
      name.startsWith("resPrice") ||
      name.startsWith("profit")
    ) {
      const index = parseInt(name.split("-")[1]);
      const updatedCost = [...cost];
      const property = name.startsWith("amount")
        ? "amount"
        : name.startsWith("price")
          ? "price"
          : name.startsWith("pimg")
            ? "pimg"
            : name.startsWith("resPrice")
              ? "resPrice"
              : name.startsWith("profit")
                ? "profit"
                : "id";
      updatedCost[index][property] = value;
      setCost(updatedCost);
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleAddProduct = async () => {
    const formData = new FormData();
    formData.append("name", form?.name);
    formData.append("api", form?.api);
    formData.append("apiName", form?.apiName);
    formData.append("gameName", form?.gameName);
    formData.append("region", form?.region);
    formData.append("tag", form?.tag);
    formData.append("stock", form?.stock);
    formData.append("desc", form?.desc);
    formData.append("descTwo", form?.descTwo);
    formData.append("youtubeLink", form?.youtubeLink);
    formData.append("use", form?.use);
    formData.append("category", JSON.stringify(form?.category));
    formData.append("cost", JSON.stringify(cost));
    formData.append("image", selectedFile);
    formData.append('preOrderExecutionTime', value)

    setLoading(true);

    try {
      const res = await axios.post("/api/product/add-product", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        navigate("/admin-products");
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      message.error(error?.response?.data?.message)
      console.error("Error uploading files:", error);
    }
  };

  const getMobileLegendGame = async () => {
    try {
      const res = await axios.post(
        "/api/product/get-mobile-legend",
        { region: form?.region },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.success) {
        setData(res.data.data.product);
      } else {
        message.error("Api Error, Try after sometime");
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function getAllImages() {
    try {
      const res = await axios.get("/api/image/get-images");
      if (res.data.success) {
        setImages(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllImages();
  }, []);

  useEffect(() => {
    if (form?.region !== "") {
      getMobileLegendGame();
    }
  }, [form?.region]);

  const fetchYokcashServices = async () => {
    try {
      const res = await axios.post("/api/yokcash/get-yokcash", {
        gameName: form?.gameName,
      });
      if (res.data.success) {
        setYokcash(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchMoogoldServices = async () => {
    try {
      const res = await axios.post("/api/moogold/moogold-product", {
        product_id: form?.gameName,
      });
      if (res.data.success) {
        setMoogold(res.data.data.Variation);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchMoogoldServers = async () => {
    try {
      const res = await axios.post("/api/moogold/moogold-servers", {
        product_id: form?.gameName,
      });
      if (res.data.success) {
        setServers(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    if (form?.apiName === "yokcash" && form?.gameName !== "") {
      fetchYokcashServices();
    } else if (form?.apiName === "moogold" && form?.gameName !== "") {
      fetchMoogoldServices();
      fetchMoogoldServers();
    }
  }, [form?.gameName]);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Add Product</h3>
        </div>
        <hr />
        <div className="add-product-container">
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="image"
              required
              multiple
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              name="name"
              onChange={handleChange}
              value={form?.name}
              type="text"
              placeholder="Enter name"
            />
          </div>
          <div className="form-fields mb-3">
            <textarea
              style={{ border: "1px solid #000" }}
              name="desc"
              id=""
              cols="30"
              rows="3"
              placeholder="Description"
              className="form-control"
              onChange={handleChange}
              value={form?.desc}
            ></textarea>
          </div>
          <div className="form-fields mb-3">
            <textarea
              style={{ border: "1px solid #000" }}
              name="descTwo"
              id=""
              cols="30"
              rows="3"
              placeholder="Description Two"
              className="form-control"
              onChange={handleChange}
              value={form?.descTwo}
            ></textarea>
          </div>
          <div className="form-fields mb-3">
            <input
              type="text"
              className="form-control w-100"
              name="youtubeLink"
              onChange={handleChange}
              placeholder="Youtube Link"
              value={form?.youtubeLink}
            />
          </div>
          <div className="form-fields mb-3">
            <Multiselect
              id="category"
              className="multiselect-wrapper"
              isObject={false}
              onRemove={handleCategoryChange}
              onSelect={handleCategoryChange}
              selectedValues={form.category}
              options={[
                "Popular",
                "Games",
                "Preorder",
                "Vouchers",
                "Social Media",
                "OTT",
                "Others",
              ]}
            />
          </div>

          //time-picker component for preorder items
          {form.category.includes('Preorder') && <div className=" mb-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                <DateTimePicker
                  label="Execute Order at"
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>

          </div>}


          <div className="form-fields mb-3">
            <select
              onChange={handleChange}
              value={form?.api}
              name="api"
              className="w-100"
            >
              <option value="">API BASED?</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          {form?.api === "yes" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.apiName}
                name="apiName"
                className="w-100"
              >
                <option value="">Select API</option>
                <option value="smileOne">Smile One Api</option>
                <option value="yokcash">Yokcash Api</option>
                <option value="moogold">Moogold Api</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "moogold" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.gameName}
                name="gameName"
                className="w-100"
              >
                <option value="">Select Game</option>
                <option value="6963">Pubg global</option>
                {/* <option value="575020">Identity V</option> */}
                <option value="4427073">Brawl stars</option>
                <option value="4427072">Clash royale</option>
                <option value="428075">Genshin impact</option>
                <option value="5177311">Honor of kings</option>
                <option value="15145">Mobile legends</option>
                <option value="2362359">Mobile Legends (Indonesia)</option>
                <option value="4690648">Mobile Legends (Malaysia)</option>
                <option value="5846232">Mobile legends (Brazil)</option>
                <option value="6637539">Mobile Legends (Russia)</option>
                <option value="8957883">Mobile Legends (Philippines)</option>
                <option value="8996566">Mobile Legends (Singapore)</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "yokcash" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.gameName}
                name="gameName"
                className="w-100"
              >
                <option value="">Select Game</option>
                <option value="Free Fire">Free Fire</option>
                <option value="Genshin Impact">Genshin Impact</option>
                <option value="Call Of Duty Mobile">Call Of Duty Mobile</option>
                <option value="PUBG Mobile">PUBG Mobile</option>
                <option value="Honkai Star Rail">Honkai Star Rail</option>
                <option value="Valorant">Valorant</option>
                <option value="Clash Of Clans">Clash Of Clans</option>
                <option value="League of Legends Wild Rift">
                  League of Legends Wild Rift
                </option>
                <option value="ML Region Luar">ML Region Luar</option>
                <option value="Mobile Legends">Mobile Legends</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "smileOne" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.region}
                name="region"
                className="w-100"
              >
                <option value="">Select Region</option>
                <option value="brazil">Brazil</option>
                <option value="philippines">Philippines</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "smileOne" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.use}
                name="use"
                className="w-100"
              >
                <option value="">Allow User</option>
                <option value="a">a</option>
                <option value="b">b</option>
                <option value="c">c</option>
              </select>
            </div>
          )}
          <div className="form-fields mb-3">
            <input
              name="tag"
              type="text"
              placeholder="Enter tag (Optional)"
              className="w-100 form-control"
              onChange={handleChange}
              value={form?.tag}
            />
          </div>
          <div className="form-fields mb-3">
            <label htmlFor="" className="text-dark">
              <small>Stock</small>
            </label>
            <select
              onChange={handleChange}
              value={form?.stock}
              name="stock"
              className="w-100"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          {cost &&
            cost?.map((item, index) => (
              <div className="d-flex form-fields mb-3" key={index}>
                <input
                  className="w-100"
                  name={`id-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.id || ""}
                  type="text"
                  placeholder="Enter id"
                />
                <input
                  className="w-100"
                  name={`amount-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.amount || ""}
                  type="text"
                  placeholder="Enter Amount"
                />
                <input
                  className="w-100"
                  name={`price-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.price || ""}
                  type="text"
                  placeholder="Enter Price"
                />
                <input
                  className="w-100"
                  name={`resPrice-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.resPrice || ""}
                  type="text"
                  placeholder="Enter Reseller Price"
                />
                <input
                  className="w-100"
                  name={`profit-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.profit || ""}
                  type="text"
                  placeholder="Enter Profit"
                />
                <input
                  className="w-100"
                  name={`pimg-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.pimg || ""}
                  type="text"
                  placeholder="Enter Image"
                />
                <button onClick={() => handleAddCostField(index)}>+</button>
                {index > 0 && (
                  <button onClick={() => handleRemoveCostField(index)}>
                    -
                  </button>
                )}
              </div>
            ))}
          <button className="w-100 py-3 add-btn" onClick={handleAddProduct}>
            Add
          </button>
        </div>
      </div>

      {/* API PRO LIST  */}
      {form?.apiName === "smileOne" && data && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>ID</th>
              <th>SPU</th>
              <th>PRICE</th>
              <th>COST PRICE</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr>
                  <td>{item?.id}</td>
                  <td>{item.spu}</td>
                  <td>{item.price}</td>
                  <td>{item.cost_price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {form?.apiName === "yokcash" && yokcash && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>Product Id</th>
              <th>Name</th>
              <th>Category</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {yokcash?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.id}</td>
                  <td>{item?.nama_layanan}</td>
                  <td>{item?.kategori}</td>
                  <td>{item?.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </AdminLayout>
  );
};

export default AdminAddProduct;
