import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { message, Pagination } from "antd";
import "./AdminQueries.css";

const AdminPayments = () => {
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedType, setSelectedType] = useState("");

  const getAllPayments = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/payment/get-all-payments",
        {
          month: selectedMonth,
          year: new Date().getFullYear(),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setPayments(res.data.data.reverse());
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPayments();
  }, [selectedMonth]);

  // Function to handle pagination change
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  // Function to handle page size change
  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  // Filter payments by email
  const filteredPayments = payments
    ? payments.filter((item) => {
        const emailMatch = item?.email
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        const typeMatch = selectedType === "" || item?.type === selectedType;

        return emailMatch && typeMatch;
      })
    : [];

  // Calculate start and end indexes for pagination
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Payments</h3>
        <h6>Total Payments - {payments?.length}</h6>
      </div>
      <hr />
      <div className="admin-queries">
        <div className="tools">
          <div className="form-fields">
            <SearchIcon className="text-dark me-2" />
            <input
              className="mb-4"
              type="search"
              name="search"
              placeholder="Search by email"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="form-fields">
            <div className="form-fields">
              <select
                className="text-dark"
                name="month"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                <option value="">Select Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>
          </div>
          <div className="form-fields">
            <div className="form-fields">
              <select
                className="text-dark"
                name="month"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
                <option value="">Select Type</option>
                <option value="order">Order</option>
                <option value="wallet">Wallet Topup</option>
              </select>
            </div>
          </div>
        </div>
        <table className="table query-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Price</th>
              <th>Amount</th>
              <th>Client Txn ID</th>
              <th>UTR</th>
              <th>Type</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          {loading ? (
            <div className="loader-container text-center">
              <span class="loader"></span>
            </div>
          ) : (
            <tbody>
              {filteredPayments
                .slice(startIndex, endIndex)
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>{item?.email}</td>
                      <td>Rs. {item?.price}</td>
                      <td>{item?.amount}</td>
                      <td>{item?.client_txn_id}</td>
                      <td>{item?.utr || "NA"}</td>
                      <td>{item?.type || "NA"}</td>
                      <td>
                        {new Date(item?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </td>
                      <td>{item?.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
        <Pagination
          current={currentPage}
          total={filteredPayments.length}
          pageSize={pageSize}
          onChange={handlePageChange}
          onShowSizeChange={handlePageSizeChange}
          className="pagination"
        />
      </div>
    </AdminLayout>
  );
};

export default AdminPayments;
