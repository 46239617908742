import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Footer/Footer.css";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useSelector } from "react-redux";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

const Footer = () => {
  const { user } = useSelector((state) => state.user);
  const [payload, setPayload] = useState('')
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setPayload(encodeURI(`${user?.fname}\n${user?.mobile}\n${user?.email}\n`))
  }, [])


  return (
    <React.Fragment>
      {location?.pathname !== "/login" &&
        location?.pathname !== "/register" &&
        location?.pathname !== "/forgot-password" && (
          <div className="container-fluid footer-container">
            <div className="tel-icon-cont">
              <Link target="_blank" to={`https://wa.me/+919402816017?text=${payload}`}>
                <WhatsAppIcon className='icon' fontSize="large" style={{ marginRight: '3px' }} />
                Support
              </Link>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
                <div className="footer-logo">
                  DEERA<span>GAMES</span>
                </div>
                <p>
                  It is your gateway to a world of gaming possibilities. Join us
                  and experience amazing games.
                </p>
                <hr />
                <p className="m-0 mb-2">
                  <LocalPhoneIcon className="me-2" />
                  <a href="tel:+919402816017" style={{ color: "white" }}>
                    +91-9402816017

                  </a>

                </p>
                <p className="m-0 mb-2">
                  <EmailIcon className="me-2" />
                  <a href="mailto:contact@deeragames.in" style={{ color: "white" }}>contact@deeragames.in</a>

                </p>
                <p className="m-0 mb-2">
                  <FmdGoodIcon className="me-2" />
                  Arunachal Pradesh
                </p>
              </div>
              <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
                <h6>Useful Links</h6>
                <ul>
                  {!user && (
                    <>
                      <li>
                        <Link to="/login">Login</Link>
                      </li>
                      <li>
                        <Link to="/register">Register</Link>
                      </li>
                    </>
                  )}
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/track-order">Track Order</Link>
                  </li>
                </ul>
              </div>
              <div className="quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
                <h6>Important Pages</h6>
                <ul>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/refund-policy">Refund Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="quick-links col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
                <h6>Connect here</h6>
                <div className="social-media-links">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/deeragames"
                  >
                    <InstagramIcon className="icon" />
                  </a>
                  <Link target="_blank" to="https://wa.me/917005047608">
                    <WhatsAppIcon className="icon" />
                  </Link>
                  <Link target="_blank" to="https://t.me/+917005047608">
                    <TelegramIcon className="icon" />
                  </Link>
                  <Link target="_blank" to="mailto:contact@deeragames.in">
                    <EmailIcon className="icon" />
                  </Link>
                </div>
                {/* <h6 className="mt-4">Contact Developer</h6>
                <Link
                  style={{ color: "var(--s)" }}
                  to="https://aashirdigital.com"
                  target="_blank"
                >
                  CLICK HERE
                </Link> */}
              </div>
              <hr />
              <span className="text-center">
                All Rights Reserved © 2024 | DEERA GAMES
              </span>
            </div>
          </div>
        )
      }
    </React.Fragment >
  );
};

export default Footer;