import React from "react";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FeedIcon from "@mui/icons-material/Feed";
import "./Features.css";

const Features = () => {
  return (
    <div className="dgf-container">
      <h5># WELCOME TO THE ESPORTS & GAMING SITE</h5>
      <h1>Crafting Legends in the Gaming World</h1>
      <div className="dgf-c">
        <div className="dgf">
          <SportsEsportsIcon className="icon" />
          <h4>Gaming Recharge</h4>
          <p>User can recharge all types of games easily using our website</p>
        </div>
        <div className="dgf">
          <FeedIcon className="icon" />
          <h4>Gaming News</h4>
          <p>
            Keep users informed about the gaming industry with news articles.
          </p>
        </div>
        <div className="dgf">
          <LiveTvIcon className="icon" />
          <h4>Live Stream</h4>
          <p>
            Integration with popular streaming platforms for live coverage of
            games
          </p>
        </div>
        <div className="dgf">
          <EmojiEventsIcon className="icon" />
          <h4>Great Tournament</h4>
          <p>Keeping users informed about upcoming tournaments</p>
        </div>
      </div>
    </div>
  );
};

export default Features;
