import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import GroupIcon from "@mui/icons-material/Group";
import InventoryIcon from "@mui/icons-material/Inventory";
import HelpIcon from "@mui/icons-material/Help";
import DiscountIcon from "@mui/icons-material/Discount";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PaymentIcon from "@mui/icons-material/Payment";
import CancelIcon from "@mui/icons-material/Cancel";
import CollectionsIcon from "@mui/icons-material/Collections";
import { Link } from "react-router-dom";

const AdminSidemenu = ({ menu, setMenu }) => {
  return (
    <div className={`admin-sidemenu-container ${menu && "active"}`}>
      <ul>
        <li>
          <Link to="/admin-dashboard">
            <HomeIcon className="me-2" />
            Dashboard
          </Link>
        </li>
        <li>
          <Link to="/admin-orders">
            <ReceiptIcon className="me-2" />
            Orders
          </Link>
        </li>
        <li>
          <Link to="/admin-products">
            <InventoryIcon className="me-2" />
            Products
          </Link>
        </li>
        <li>
          <Link to="/admin-users">
            <GroupIcon className="me-2" />
            Customers
          </Link>
        </li>{" "}
        <li>
          <Link to="/admin-payments">
            <PaymentIcon className="me-2" />
            Payments
          </Link>
        </li>
        <li>
          <Link to="/admin-queries">
            <HelpIcon className="me-2" />
            Queries
          </Link>
        </li>
        <li>
          <Link to="/admin-add-coupon">
            <DiscountIcon className="me-2" />
            Coupons
          </Link>
        </li>
        <li>
          <Link to="/admin-gallery">
            <CollectionsIcon className="me-2" />
            Gallery
          </Link>
        </li>
        <li>
          <Link to="/admin-notification">
            <NotificationsActiveIcon className="me-2" />
            Notification
          </Link>
        </li>
      </ul>
      <CancelIcon
        onClick={() => setMenu(!menu)}
        className="text-dark cancel-icon"
      />
    </div>
  );
};

export default AdminSidemenu;
