import Layout from "../components/Layout/Layout";
import React, { useEffect, useState } from "react";
import DashboardLayout from "./components/DashboardLayout";
import axios from "axios";
import { useSelector } from "react-redux";
import { message, Pagination } from "antd";
import "./Orders.css";
import IMAGES from "../img/image";

const Orders = () => {
  const { user } = useSelector((state) => state.user);
  const [allOrders, setAllOrders] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [userId, setUserId] = useState("");
  const [products, setProducts] = useState(null);

  const getAllUserOrders = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/order/get-user-orders",
        { email: user?.email },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        const sortedOrders = res.data.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setAllOrders(sortedOrders);
        setData(sortedOrders);
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (user !== null) {
      getAllUserOrders();
    }
  }, [user]);

  useEffect(() => {
    const filterOrder = allOrders?.filter((item) =>
      item.userId?.includes(userId)
    );
    setAllOrders(filterOrder);
    if (userId === "" || userId === null) {
      setAllOrders(data);
    }
  }, [userId]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const displayedOrders = allOrders?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  async function getProducts() {
    try {
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getProducts();
  }, []);

  function statusClass(val) {
    switch (val) {
      case "pending":
        return "bg-warning text-dark";
      case "processing":
        return "bg-primary text-white";
      case "success":
        return "bg-success text-white";
      case "cancelled":
        return "bg-danger text-white";
      case "failed":
        return "bg-danger text-white";
      default:
        return "text-dark";
    }
  }

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-order-container">
          {loading ? (
            <div className="loader-container text-center">
              <span className="loader"></span>
            </div>
          ) : (
            <>
              <div className="tools">
                <input
                  type="text"
                  placeholder="Search User Id"
                  className="form-control p-2"
                  onChange={(e) => setUserId(e.target.value)}
                />
              </div>

              <div className="order-container">
                {displayedOrders?.map((item, index) => {
                  const matchedProduct = products?.find(
                    (product) => product?.name === item?.p_info
                  );
                  const imageUrl = matchedProduct ? matchedProduct.image : "";

                  return (
                    <div className="order" key={index}>
                      <div className="order-date">
                        <span>
                          {new Date(item?.createdAt).toLocaleString("default", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          })}
                        </span>
                        <span
                          className={`${statusClass(item.status)} p-1 py-0`}
                        >
                          {item.status.toUpperCase()}
                        </span>
                      </div>
                      <div className="order-img-content">
                        <div className="orderimg">
                          <img
                            src={imageUrl || IMAGES.default}
                            alt={item?.p_info || "Product Image"}
                          />
                        </div>
                        <div className="ordercontent">
                          <p>{item?.client_txn_id}</p>
                          <p>
                            <b>{item?.p_info}</b>
                          </p>
                          <p className="text-success">
                            {item?.userId} {item?.zoneId && "-"}{" "}
                            {item?.zoneId && item?.zoneId}
                          </p>
                          <p>{item?.amount}</p>
                          <p>₹{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <Pagination
                className="my-3"
                current={currentPage}
                pageSize={pageSize}
                total={allOrders?.length}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={handlePageSizeChange}
              />
            </>
          )}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Orders;
